import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getTasks(filter) {
    console.log('get tasks')
    const serialised = this.serialiseMessageFilter(filter)
    const url = `audanet/tasks/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseMessageFilter(filter) {
    const proto = require('../../assets/proto/audanet/AudaNetTaskFilter.proto')
    const filterMessage = proto.AudaNetTaskFilter.AudaNetTaskFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getQuoteSummaries(taskId) {
    const url = `audanet/tasks/quotes/${taskId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getJobInfo(taskId) {
    const url = `audanet/tasks/jobinfo/${taskId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async loadTask(options) {
    const url = `audanet/load/${options.taskId}`
    return FqApi.put(url, options)
  },
  async getQuoteNo(quoteId) {
    const url = `/ormmessages/load/getquoteno/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async createSubQuote(quoteId) {
    const url = `audanet/createsubquote/${quoteId}`
    // const result = await FqApi.get(url)
    return FqApi.put(url)
    // return result.data
  },
  async assertVehicleExists(rego) {
    const url = `/ormmessages/load/vehicleexists?rego=${encodeURIComponent(rego)}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getExternalSetting(settingName) {
    const url = `companyexternalsettings/${settingName}`
    const result = await FqApi.get(url)
    return result.data
  }
}
