<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child box detail-page-tile">
          <div class="columns">
            <div class="column">
              <p class="title">PNET{{ pnetAudanetMenuText }}</p>
            </div>
            <div class="column is-narrow">
              <div v-show="!value.isNew"
                v-if="value.isPnet && !value.isAudaBridge"
                class="dropdown is-right is-hoverable">
                <div class="dropdown-trigger">
                  <button class="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu-pnet">
                    <span>PNET</span>
                    <span class="icon is-small">
                      <i class="mdi mdi-chevron-down"
                        aria-hidden="true" />
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu"
                  id="dropdown-menu-pnet"
                  role="menu">
                  <div class="dropdown-content">
                    <a v-if="!value.isAudaNet && isSubmitQuoteEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="submitPnetQuote">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-upload mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Quote</span>
                    </a>
                    <a v-if="!value.isAudaNet && isSubmitSupplementaryEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="submitPnetQuote">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-upload-outline mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Supplementary Quote</span>
                    </a>
                    <a v-if="isSubmitQuoteUpdateEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="submitPnetQuoteUpdate">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-update mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Repair Status</span>
                    </a>
                    <a v-if="isSubmitTaxInvoiceEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="submitTaxInvoice">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-currency-usd mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Tax Invoice</span>
                    </a>
                    <hr v-if="(!value.isAudaNet && (isSubmitQuoteEnabled || isSubmitSupplementaryEnabled)) || isSubmitQuoteUpdateEnabled || isSubmitTaxInvoiceEnabled"
                      class="dropdown-divider">
                    <a v-if="$userInfo.isSupportUser"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="showUtilities()">
                      <span class="icon is-small is-left has-text-primary"
                        style="self-align: center">
                        <i class="mdi mdi-tools mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Utilities</span>
                    </a>
                    <a href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="viewMessages('PNET')">
                      <span class="icon is-small is-left has-text-primary"
                        style="self-align: center">
                        <i class="mdi mdi-file-code mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">View Messages</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-narrow">
              <div v-show="!value.isNew"
                v-if="value.isAudaNet || value.isAudaBridge"
                class="dropdown is-right is-hoverable">
                <div class="dropdown-trigger">
                  <button class="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu-audanet">
                    <span>{{ dropdownMenuText }}</span>
                    <span class="icon is-small">
                      <i class="mdi mdi-chevron-down"
                        aria-hidden="true" />
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu"
                  id="dropdown-menu-audanet"
                  role="menu">
                  <div class="dropdown-content">
                    <a v-if="value.isAudaNet"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="showDownloadAudaNetQuote">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-download mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Download Quote</span>
                    </a>
                    <a v-if="value.isAudaNet"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="downloadImages">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-image-plus mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Download Images</span>
                    </a>
                    <a v-if="value.isAudaNet"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="updateClaimInfo">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-shield-car mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Update Claim Info</span>
                    </a>
                    <a v-if="value.isAudaNet"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="downloadVehicle">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-car mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Update Vehicle</span>
                    </a>
                    <a v-if="value.isAudaNet"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="showSubmitAudaNetItems">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-upload-multiple mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Items</span>
                    </a>
                    <a v-if="value.isAudaBridge"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="submitImages">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-image-move mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Images</span>
                    </a>
                    <hr v-if="value.isAudaNet"
                      class="dropdown-divider">
                    <a href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="viewMessages('audanet')">
                      <span class="icon is-small is-left has-text-primary"
                        style="self-align: center">
                        <i class="mdi mdi-file-code mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">View Messages</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="columns">
              <div v-if="value.pnet"
                class="column">
                <label class="label">PNET</label>
                <div class="columns">
                  <div class="column is-narrow">
                    <table class="table is-bordered is-striped table-max-width">
                      <tr>
                        <td nowrap
                          class="has-text-weight-bold has-vertical-middle">Quote Status</td>
                        <td>
                          <div v-if="$userInfo.isSupportUser"
                            class="select is-fullwidth">
                            <select v-model="value.pnet.quoteStatusId">
                              <option v-for="type in quoteStatusTypes"
                                :key="type.value"
                                :value="type.value">
                                {{ type.name }}
                              </option>
                            </select>
                          </div>
                          <div v-else>{{ quoteStatusTypes.find(s => s.value === value.pnet.quoteStatusId).name }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td nowrap
                          class="has-text-weight-bold has-vertical-middle">Quote Request No.</td>
                        <td>
                          <input v-if="$userInfo.isSupportUser"
                            class="input is-uppercase"
                            v-model="value.pnet.quoteRequestNo"
                            type="text"
                            placeholder="Quote Request No.">
                          <span v-else>{{ value.pnet.quoteRequestNo }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td nowrap
                          class="has-text-weight-bold has-vertical-middle">Quote Request Version</td>
                        <td>
                          <input v-if="$userInfo.isSupportUser"
                            class="input is-uppercase"
                            v-model="value.pnet.quoteRequestVersion"
                            type="text"
                            placeholder="Quote Request Version">
                          <span v-else>{{ value.pnet.quoteRequestVersion }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td nowrap
                          class="has-text-weight-bold has-vertical-middle">PNET Quote No.</td>
                        <td>
                          <input v-if="$userInfo.isSupportUser"
                            class="input is-uppercase"
                            v-model="value.pnet.pnetQuoteNo"
                            type="text"
                            placeholder="PNET Quote No.">
                          <span v-else>{{ value.pnet.pnetQuoteNo }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td nowrap
                          class="has-text-weight-bold has-vertical-middle">Authorised Quote No.</td>
                        <td>
                          <input v-if="$userInfo.isSupportUser"
                            class="input is-uppercase"
                            v-model="value.pnet.authorisedQuoteNo"
                            type="text"
                            placeholder="Authorised Quote No.">
                          <span v-else>{{ value.pnet.authorisedQuoteNo }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td nowrap
                          class="has-text-weight-bold has-vertical-middle">Authority No.</td>
                        <td class="has-vertical-middle">
                          <the-mask v-if="$userInfo.isSupportUser"
                            class="input"
                            placeholder="Authority No."
                            v-model="value.pnet.authorityNo"
                            :mask="['####################']" />
                          <span v-else>{{ value.pnet.authorityNo }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td nowrap
                          class="has-text-weight-bold has-vertical-middle">Payment Response</td>
                        <td>
                          <span>{{ value.pnet.paymentResponse }}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div v-if="value.pnet && $userInfo.isSupportUser"
                    class="column">
                    <div class="field has-checkradio">
                      <div class="control">
                        <input class="is-checkradio is-primary"
                          :class="{ 'has-background-color': value.pnet.extraVehicleInfo }"
                          type="checkbox"
                          v-model="value.pnet.extraVehicleInfo"
                          id="extraVehicleInfo">
                        <label for="extraVehicleInfo">Extra Vehicle Info</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="value.audatex"
                class="column">
                <label class="label">{{ dropdownMenuText }}</label>
                <table class="table is-bordered is-striped table-max-width">
                  <tr>
                    <td nowrap
                      class="has-text-weight-bold has-vertical-middle">Display Name</td>
                    <td>
                      <input v-if="$userInfo.isSupportUser"
                        class="input is-uppercase"
                        v-model="value.audatex.displayName"
                        type="text"
                        placeholder="Display Name">
                      <span v-else>{{ value.audatex.displayName }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td nowrap
                      class="has-text-weight-bold">Calculation ID</td>
                    <td>{{ value.audatex.calculationId }}</td>
                  </tr>
                  <tr>
                    <td nowrap
                      class="has-text-weight-bold">Calculation Status</td>
                    <td>{{ value.audatex.calculationStatus }}</td>
                  </tr>
                  <tr>
                    <td nowrap
                      class="has-text-weight-bold">Business Status</td>
                    <td>{{ value.audatex.businessStatusText }}</td>
                  </tr>
                  <tr>
                    <td nowrap
                      class="has-text-weight-bold">Quote Stage</td>
                    <td>{{ value.audatex.quoteStage }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </article>
        <article class="tile is-child box detail-page-tile">
          <p class="title">Remarks</p>
          <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :page-index="filter.pageIndex"
            :page-size="filter.pageSize"
            :total-rows="remarkTotalRows"
            :is-loading="isTableLoading"
            :sort-column="filter.sortColumn"
            :sort-order="filter.sortOrder"
            :show-header="false"
            @pageChanged="onPageChange"
            @sort="sort">
            <tr v-for="(remark, index) in pagedRemarks"
              :key="remark.remarkId"
              @click="highlightSelected(index, $event)">
              <td class="is-content-width">
                <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
              </td>
              <td class="is-remark">
                <div>{{ remark.remarks }}</div>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </article>
      </div>
    </div>
    <audanet-download-quote-modal v-if="isDownloadQuoteModalActive"
      :active.sync="isDownloadQuoteModalActive"
      @cancel="isDownloadQuoteModalActive = false"
      @download="downloadQuote" />
    <audanet-submit-items-modal v-if="isSubmitItemsModalActive"
      :active.sync="isSubmitItemsModalActive"
      v-model="value"
      @cancel="isSubmitItemsModalActive = false"
      @submit="submitItems" />
    <xml-viewer-modal v-if="isXmlViewerModalActive"
      :active.sync="isXmlViewerModalActive"
      :quote-id="value.quoteId"
      :message-type="messageType"
      @close="isXmlViewerModalActive = false">
      <p slot="text-title">View {{ messageType }} Messages</p>
    </xml-viewer-modal>
    <pnet-submit-quote-modal v-if="isPnetSubmitQuoteModalActive"
      :active.sync="isPnetSubmitQuoteModalActive"
      v-model="value"
      :vehicle="vehicle"
      :customer="customer"
      @close="isPnetSubmitQuoteModalActive = false">
      <p slot="text-title">Submit PNET Quote</p>
    </pnet-submit-quote-modal>
    <pnet-submit-quote-update-modal v-if="isPnetSubmitQuoteUpdateModalActive"
      :active.sync="isPnetSubmitQuoteUpdateModalActive"
      v-model="value"
      :vehicle="vehicle"
      :customer="customer"
      @close="isPnetSubmitQuoteUpdateModalActive = false">
      <p slot="text-title">Submit PNET Quote Update</p>
    </pnet-submit-quote-update-modal>
    <audabridge-submit-images-modal v-if="isAudabridgeSubmitImagesModalActive"
      :active.sync="isAudabridgeSubmitImagesModalActive"
      v-model="value"
      :quote-id="value.quoteId"
      @cancel="isAudabridgeSubmitImagesModalActive = false" />
    <orm-pnet-utilities-modal v-if="isPnetUtilitiesModalActive"
      v-model="innerValue"
      :active.sync="isPnetUtilitiesModalActive" />
    <confirm-modal :active.sync="isCreateSubQuoteModalActive"
      @ok="createSubQuote(true)"
      @cancel="createSubQuote(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Create Supplementary Quote</p>
      <section slot="text-content">
        <div v-if="isCreateSubQuoteModalActive">
          <p>Found new supplementary quote added in {{ dropdownMenuText }}</p>
          <br>
          <p style="margin-left: 2em">
            Quote No.:
            <span class="has-text-primary has-text-weight-bold">{{ subQuoteSummary.quoteNoRef }}</span><br>Current Supp:
            <span class="has-text-primary has-text-weight-bold">{{ subQuoteSummary.subQuoteNo }}</span><br>New supp:
            <span class="has-text-primary has-text-weight-bold">{{ subQuoteSummary.createSubQuoteNo }}</span>
          </p>
          <br> Create new supplementary quote?
        </div>
      </section>
    </confirm-modal>
  </div>
</template>

<script>
import { AudanetDownloadQuoteModal, AudanetSubmitItemsModal, PnetSubmitQuoteModal, PnetSubmitQuoteUpdateModal, AudabridgeSubmitImagesModal } from './components'
import { AudaNetService, PnetMessageService } from './services'
import AudanetTaskService from '@/views/audanettask/AudanetTaskService'
import { EventHubTypes, PnetQuoteStatusTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import { ConfirmModal } from '@/components/BulmaModal'
import { TheMask } from 'vue-the-mask'
import { InvoiceTypes } from '@/enums'
import { RemarkColumns } from './columns'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { XmlViewerModal } from '@/components/XmlViewer'
import { QuoteNotificationMixin } from './mixins'
import { PnetService } from '@/services'
import StoreMixin from './storeMixin'
import QuoteStoreMixin from './mixins/QuoteStoreMixin'
import OrmPnetUtilitiesModal from './components/OrmPnetUtilitiesModal'
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'QuotePnetAudanet',
  inject: ['$vv'],
  components: {
    AudanetDownloadQuoteModal,
    AudanetSubmitItemsModal,
    BulmaTable,
    TheMask,
    XmlViewerModal,
    PnetSubmitQuoteModal,
    PnetSubmitQuoteUpdateModal,
    AudabridgeSubmitImagesModal,
    OrmPnetUtilitiesModal,
    ConfirmModal
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, QuoteNotificationMixin, QuoteStoreMixin, StoreMixin],
  props: {
    value: null,
    vehicle: null,
    customer: null
  },
  data() {
    return {
      innerValue: null,
      isDownloadQuoteModalActive: false,
      isSubmitItemsModalActive: false,
      isXmlViewerModalActive: false,
      isPnetSubmitQuoteModalActive: false,
      isPnetSubmitQuoteUpdateModalActive: false,
      isAudabridgeSubmitImagesModalActive: false,
      isCreateSubQuoteModalActive: false,
      filter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 8
      },
      isTableLoading: false,
      messageType: '',
      error: false,
      selectedImages: [],
      quoteStatusTypes: [],
      isPnetUtilitiesModalActive: false,
      subQuoteSummary: null
    }
  },
  errorCaptured(err, vm, info) {
    this.error = true
  },
  computed: {
        $v() {
      return this.$vv || this.$v
    },
    columns() {
      return RemarkColumns
    },
    remarkTotalRows() {
      return this.activeRemarks.length
    },
    activeRemarks() {
      const activeRemarks = this.value.otherRemarks.filter((r) => !r.isDeleted)
      return _orderBy(activeRemarks, 'remarkDateTime', 'desc')
    },
    pagedRemarks() {
      return this.activeRemarks.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    },
    pnetAudanetMenuText() {
      if (this.value.isAudaNet) {
        return this.value.audatex && this.value.audatex.taskType ? `/${this.value.audatex.taskType}` : '/AudaNet'
      } else {
        return ''
      }
    },
    dropdownMenuText() {
      if (this.value.isPnet || this.value.isAudaNet || this.value.isAudaBridge) {
        return this.value.audatex && this.value.audatex.taskType ? `${this.value.audatex.taskType}` : 'AudaNet'
      } else {
        return ''
      }
    },
    isSubmitQuoteEnabled() {
      return !this.value.readOnly && this.value.pnet.quoteStatusId === PnetQuoteStatusTypes.QuoteRequestActive
    },
    isSubmitQuoteUpdateEnabled() {
      return this.value.pnet && this.value.pnet.authorityNo && this.value.pnet.authorisedQuoteNo
    },
    isSubmitSupplementaryEnabled() {
      return (
        this.value.subQuoteNo > 0 &&
        (this.value.pnet.quoteStatusId !== PnetQuoteStatusTypes.SupplementaryActive ||
          this.value.pnet.quoteStatusId !== PnetQuoteStatusTypes.SupplementaryRejected ||
          this.value.pnet.quoteStatusId !== PnetQuoteStatusTypes.QuoteAuthorised ||
          this.value.pnet.quoteStatusId !== PnetQuoteStatusTypes.QuoteInvoiceSubmitted)
      )
    },
    isSubmitTaxInvoiceEnabled() {
      // console.log(this.value.invoices.some((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted))
      return (
        this.value.invoices.some((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted) &&
        this.value.pnet.quoteStatusId !== PnetQuoteStatusTypes.QuoteInvoiceSubmitted &&
        this.value.pnet.authorityNo
      )
    },
    tileTitle() {
      if (this.value.isAudaNet) {
        return 'AudaNet'
      } else if (this.value.isAudaBridge) {
        return 'AudaBridge'
      }
    }
  },
  watch: {
    // 'innerValue.orm.outOfScope': function(newVal, oldVal) {
    //   this.$emit('input', newVal)
    // }
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.$route.meta.title = `PNET${this.pnetAudanetMenuText}`
    this.getPnetQuoteStatusTypes()
    // this.$eventHub.$on(EventHubTypes.EntityReload, () => {
    //   this.value = this.value
    //   console.log('reload')
    // })
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    sort(name, order) {},
    async getPnetQuoteStatusTypes() {
      this.quoteStatusTypes = await PnetService.getPnetQuoteStatusTypes()
    },
    async downloadQuote(overwrite) {
      this.isDownloadQuoteModalActive = false
      this.$showSpinner('Downloading quote')
      const result = await AudaNetService.loadQuote(this.value.quoteId, overwrite)
      console.log(result)
      this.$hideSpinner()
      // this.$router.go(this.$route.fullPath)
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async updateClaimInfo() {
      if (!this.snapshotDiff) {
        this.$showSpinner('Updating Claim Info')
        const result = await AudaNetService.updateClaimInfo(this.value.quoteId)
        console.log(result)
        this.$hideSpinner()
        this.$eventHub.$emit(EventHubTypes.EntityReload)
      } else {
        this.showQuoteChangedToast()
      }
    },
    async downloadVehicle() {
      if (!this.snapshotDiff) {
        this.$showSpinner('Updating Vehicle')
        const result = await AudaNetService.loadVehicle(this.value.quoteId)
        console.log(result)
        this.$hideSpinner()
        this.$eventHub.$emit(EventHubTypes.EntityReload)
      } else {
        this.showQuoteChangedToast()
      }
    },
    async downloadImages() {
      if (!this.snapshotDiff) {
        this.$showSpinner('Downloading images')
        const result = await AudaNetService.loadImages(this.value.quoteId)
        console.log(result)
        this.$hideSpinner()
        this.$eventHub.$emit(EventHubTypes.EntityReload)
      } else {
        this.showQuoteChangedToast()
      }
    },
    async submitImages() {
      this.isAudabridgeSubmitImagesModalActive = true
      // this.$showSpinner('Submitting images')
      // const result = await AudaNetService.submitImages(this.value.quoteId)
      // console.log(result)
      // this.$hideSpinner()
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async submitSublets() {
      this.$showSpinner('Submitting sublets')
      const result = await AudaNetService.submitSublets(this.value.quoteId)
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async submitParts() {
      this.$showSpinner('Submitting parts')
      const result = await AudaNetService.submitParts(this.value.quoteId)
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async submitLabours() {
      this.$showSpinner('Submitting labours')
      const result = await AudaNetService.submitLabours(this.value.quoteId)
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async submitPaints() {
      this.$showSpinner('Submitting paints')
      const result = await AudaNetService.submitPaints(this.value.quoteId)
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async submitItems(isSubmitLabours, isSubmitPaints, isSubmitParts, isSubmitSublets) {
      this.isSubmitItemsModalActive = false
      this.$v.audaNetHeaderGroup.$touch()
      if (this.$v.audaNetHeaderGroup.$error) {
        this.$notification.openNotificationWithType('danger', 'AudaNet', 'Send cancelled. Required fields are missing or invalid')
        return
      }
      this.$showSpinner('Submitting items')
      const submit = []
      if (isSubmitParts) {
        const result = await AudaNetService.submitParts(this.value.quoteId)
        console.log(result)
        await AudaNetService.sleep(submit.length * 2000)
        // submit.splice(submit.length, 1, submit.length)
        // await AudaNetService.submitParts(this.value.quoteId)
        //submit.splice(submit.length, 1, AudaNetService.submitParts(this.value.quoteId, submit.length))
      }
      if (isSubmitSublets) {
        // await AudaNetService.sleep(submit.length * 2000)
        // submit.splice(submit.length, 1, submit.length)
        // await AudaNetService.submitSublets(this.value.quoteId)
        submit.splice(submit.length, 1, AudaNetService.submitSublets(this.value.quoteId, submit.length))
      }
      if (isSubmitPaints) {
        // await AudaNetService.sleep(submit.length * 2000)
        // submit.splice(submit.length, 1, submit.length)
        // await AudaNetService.submitPaints(this.value.quoteId)
        submit.splice(submit.length, 1, AudaNetService.submitPaints(this.value.quoteId, submit.length))
      }
      if (isSubmitLabours) {
        // await AudaNetService.sleep(submit.length * 2000)
        // submit.splice(submit.length, 1, submit.length)
        // await AudaNetService.submitLabours(this.value.quoteId)
        submit.splice(submit.length, 1, AudaNetService.submitLabours(this.value.quoteId, submit.length))
      }
      await Promise.all(submit)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.$notification.openNotificationWithType('success' , this.tileTitle, 'Items submitted')
    },
    viewMessages(type) {
      this.messageType = type
      this.isXmlViewerModalActive = true
    },
    async submitTaxInvoice() {
      if (!this.snapshotDiff) {
        this.$showSpinner('Submitting tax invoice')
        const result = await PnetMessageService.submitTaxInvoice(this.value.quoteId)
        console.log(result)
        this.$hideSpinner()
        this.$eventHub.$emit(EventHubTypes.EntityReload)
        this.showSuccessToast('Tax invoice is being submitted')
      } else {
        this.showQuoteChangedToast()
      }
    },
    submitPnetQuote() {
      if (!this.snapshotDiff) {
        this.isPnetSubmitQuoteModalActive = true
      } else {
        this.showQuoteChangedToast()
      }
    },
    submitPnetQuoteUpdate() {
      if (!this.snapshotDiff) {
        this.isPnetSubmitQuoteUpdateModalActive = true
      } else {
        this.showQuoteChangedToast()
      }
    },
    showSubmitAudaNetItems() {
      if (!this.snapshotDiff) {
        this.isSubmitItemsModalActive = true
      } else {
        this.showQuoteChangedToast()
      }
    },
    async showDownloadAudaNetQuote() {
      if (!this.snapshotDiff) {
        // check if subQuoteCreated
        this.$showSpinner('Checking quote...')
        this.subQuoteSummary = await AudaNetService.checkSubQuoteAdded(this.value.quoteId)
        this.$hideSpinner()
        if (this.subQuoteSummary != null && this.subQuoteSummary.isCreateQuote) {
          this.isCreateSubQuoteModalActive = true
        } else {
          this.isDownloadQuoteModalActive = true
        }
      } else {
        this.showQuoteChangedToast()
      }
    },
    async createSubQuote(create) {
      if (create) {
        this.isCreateSubQuoteModalActive = false
        this.$showSpinner('Creating supplementry')
        let result = await AudanetTaskService.createSubQuote(this.value.quoteId)
        this.$hideSpinner()
        this.$showSpinner('Loading supplementary')
        result = await AudaNetService.loadQuote(result.data, true)
        this.$hideSpinner()
        this.$router.push({
          name: this.$route.name,
          params: { quoteId: result.data }
        })
      } else {
        this.isCreateSubQuoteModalActive = false
      }
    },
    showUtilities() {
      this.isPnetUtilitiesModalActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
a.dropdown-item-has-icon {
  display: flex;
  align-items: center;
}
span.dropdown-item-text {
  padding-left: 5px;
}
.table-max-width {
  max-width: 30rem;
}
</style>
