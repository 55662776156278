/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.AudaNetTaskFilter = (function() {

    /**
     * Namespace AudaNetTaskFilter.
     * @exports AudaNetTaskFilter
     * @namespace
     */
    var AudaNetTaskFilter = {};

    AudaNetTaskFilter.AudaNetTaskFilterModel = (function() {

        /**
         * Properties of an AudaNetTaskFilterModel.
         * @memberof AudaNetTaskFilter
         * @interface IAudaNetTaskFilterModel
         * @property {string|null} [claimNo] AudaNetTaskFilterModel claimNo
         * @property {string|null} [displayName] AudaNetTaskFilterModel displayName
         * @property {string|null} [taskStatus] AudaNetTaskFilterModel taskStatus
         * @property {string|null} [taskType] AudaNetTaskFilterModel taskType
         * @property {string|null} [businessStatusText] AudaNetTaskFilterModel businessStatusText
         * @property {string|null} [creationDate] AudaNetTaskFilterModel creationDate
         * @property {string|null} [sortColumn] AudaNetTaskFilterModel sortColumn
         * @property {string|null} [sortOrder] AudaNetTaskFilterModel sortOrder
         * @property {number|null} [pageIndex] AudaNetTaskFilterModel pageIndex
         * @property {number|null} [pageSize] AudaNetTaskFilterModel pageSize
         */

        /**
         * Constructs a new AudaNetTaskFilterModel.
         * @memberof AudaNetTaskFilter
         * @classdesc Represents an AudaNetTaskFilterModel.
         * @implements IAudaNetTaskFilterModel
         * @constructor
         * @param {AudaNetTaskFilter.IAudaNetTaskFilterModel=} [p] Properties to set
         */
        function AudaNetTaskFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AudaNetTaskFilterModel claimNo.
         * @member {string|null|undefined} claimNo
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.claimNo = null;

        /**
         * AudaNetTaskFilterModel displayName.
         * @member {string|null|undefined} displayName
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.displayName = null;

        /**
         * AudaNetTaskFilterModel taskStatus.
         * @member {string|null|undefined} taskStatus
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.taskStatus = null;

        /**
         * AudaNetTaskFilterModel taskType.
         * @member {string|null|undefined} taskType
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.taskType = null;

        /**
         * AudaNetTaskFilterModel businessStatusText.
         * @member {string|null|undefined} businessStatusText
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.businessStatusText = null;

        /**
         * AudaNetTaskFilterModel creationDate.
         * @member {string|null|undefined} creationDate
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.creationDate = null;

        /**
         * AudaNetTaskFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.sortColumn = null;

        /**
         * AudaNetTaskFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.sortOrder = null;

        /**
         * AudaNetTaskFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.pageIndex = null;

        /**
         * AudaNetTaskFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        AudaNetTaskFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * AudaNetTaskFilterModel _claimNo.
         * @member {"claimNo"|undefined} _claimNo
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_claimNo", {
            get: $util.oneOfGetter($oneOfFields = ["claimNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _displayName.
         * @member {"displayName"|undefined} _displayName
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_displayName", {
            get: $util.oneOfGetter($oneOfFields = ["displayName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _taskStatus.
         * @member {"taskStatus"|undefined} _taskStatus
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_taskStatus", {
            get: $util.oneOfGetter($oneOfFields = ["taskStatus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _taskType.
         * @member {"taskType"|undefined} _taskType
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_taskType", {
            get: $util.oneOfGetter($oneOfFields = ["taskType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _businessStatusText.
         * @member {"businessStatusText"|undefined} _businessStatusText
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_businessStatusText", {
            get: $util.oneOfGetter($oneOfFields = ["businessStatusText"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _creationDate.
         * @member {"creationDate"|undefined} _creationDate
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_creationDate", {
            get: $util.oneOfGetter($oneOfFields = ["creationDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AudaNetTaskFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @instance
         */
        Object.defineProperty(AudaNetTaskFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified AudaNetTaskFilterModel message. Does not implicitly {@link AudaNetTaskFilter.AudaNetTaskFilterModel.verify|verify} messages.
         * @function encode
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @static
         * @param {AudaNetTaskFilter.IAudaNetTaskFilterModel} m AudaNetTaskFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudaNetTaskFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.claimNo != null && Object.hasOwnProperty.call(m, "claimNo"))
                w.uint32(10).string(m.claimNo);
            if (m.displayName != null && Object.hasOwnProperty.call(m, "displayName"))
                w.uint32(18).string(m.displayName);
            if (m.taskStatus != null && Object.hasOwnProperty.call(m, "taskStatus"))
                w.uint32(26).string(m.taskStatus);
            if (m.taskType != null && Object.hasOwnProperty.call(m, "taskType"))
                w.uint32(34).string(m.taskType);
            if (m.businessStatusText != null && Object.hasOwnProperty.call(m, "businessStatusText"))
                w.uint32(42).string(m.businessStatusText);
            if (m.creationDate != null && Object.hasOwnProperty.call(m, "creationDate"))
                w.uint32(50).string(m.creationDate);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(58).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(66).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(72).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(80).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified AudaNetTaskFilterModel message, length delimited. Does not implicitly {@link AudaNetTaskFilter.AudaNetTaskFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @static
         * @param {AudaNetTaskFilter.IAudaNetTaskFilterModel} message AudaNetTaskFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudaNetTaskFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AudaNetTaskFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {AudaNetTaskFilter.AudaNetTaskFilterModel} AudaNetTaskFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudaNetTaskFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.AudaNetTaskFilter.AudaNetTaskFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.claimNo = r.string();
                    break;
                case 2:
                    m.displayName = r.string();
                    break;
                case 3:
                    m.taskStatus = r.string();
                    break;
                case 4:
                    m.taskType = r.string();
                    break;
                case 5:
                    m.businessStatusText = r.string();
                    break;
                case 6:
                    m.creationDate = r.string();
                    break;
                case 7:
                    m.sortColumn = r.string();
                    break;
                case 8:
                    m.sortOrder = r.string();
                    break;
                case 9:
                    m.pageIndex = r.int32();
                    break;
                case 10:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an AudaNetTaskFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AudaNetTaskFilter.AudaNetTaskFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AudaNetTaskFilter.AudaNetTaskFilterModel} AudaNetTaskFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudaNetTaskFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return AudaNetTaskFilterModel;
    })();

    return AudaNetTaskFilter;
})();

module.exports = $root;
